<template>
    <col :o365-field="columnDefinition.colId"/>
</template>

<script setup lang="ts">
/**
  * Special column configuration component used in grids to enable Node Data extension. 
  * @definition
  */
import type { NodeItem } from 'o365.modules.DataObject.extensions.NodeData.ts';
import type DataColumn from 'o365.controls.DataGrid.Column.ts';
import type DataGridControl from 'o365.controls.DataGrid.ts';
import type { Ref } from 'vue';

import $t from 'o365.modules.translate.ts';
import { dataGridControlKey, dataColumnGroupKey } from 'o365.modules.vue.injectionKeys.js'
import { onMounted, onUnmounted, computed, inject, useSlots, useAttrs, watch } from 'vue';
import utils from 'o365.modules.utils.js';

import { TreeColumnHeaderFactory, ExpandableCellRenderer } from 'o365.modules.DataObject.extensions.NodeData.ts';
import 'o365.controls.DataGrid.extensions.NodeData.ts';

const props = withDefaults(defineProps<{
    field?: string;
    editable?: boolean | ((node: NodeItem) => boolean);
    headerName?: string;
    headerTitle?: string;
    filter?:any;
    width?: number|string;
    minWidth?: string|number;
    flexWidth?: string|number;
    pinned?: 'left'|'right';
    cellTitle?: string | ((node: NodeItem) => string);
    indent?: number|string;
    getDisplay?: (node: NodeItem) => any;
    boldDisplay?: boolean;

    cellStyle?: any
    classFn?: (node: NodeItem) => any;
    getCopyValue?: (node: NodeItem) => any;
    filterField?: string;
    sortField?: string;
    sortable?: boolean;
    disableMenu?: boolean;
    disableResize?: boolean;
    cellRendererParams?: any;
    cellrendererparams?: any;
    /** When true the default renderer will display column captions for group by configurations  */
    showPrefix?: boolean;
    /** When true the default rednerer will not display detail counts */
    hideCount?: boolean;
    directDetailsCount?: boolean,
    /** Enable group by mode in grid */
    groupBy?: boolean;
    /** Enable client side node structure */
    loadFullStructure?: boolean;
    /** Enable user defined aggregates */
    userAggregates?: boolean;
    /** When enabled this column will only be visible in grid when there is active group by */
    onlyShowOnGroupBy?: boolean;
    format?: string;
    /** Disable layout module for group by */
    disableLayouts?: boolean;
}>(), {
    headerName: $t('Group'),
    filter: (raw) => (raw.field || raw.filterField) ? 'OFilter' : false,
    width: 400,
    minWidth: 50,
    indent: 24,
});

const slots = useSlots();
const attrs = useAttrs();

const colId = 'AutoTreeGroup';

const dataGridControl = inject<Ref<DataGridControl>|null>(dataGridControlKey, null);
const parentGroupId = inject(dataColumnGroupKey, null);

const TreeHeaderSlot = TreeColumnHeaderFactory(dataGridControl!.value, 'o365.vue.components.NodeDataLevels.vue');
/** Props combined with system column definition */
const columnDefinition = computed(() => {
    return {
        colId: colId,
        field: props.field,
        editable: props.editable,
        headerName: props.headerName,
        headerTitle: props.headerTitle ?? props.headerName,
        filter: props.filter,
        filterdropdown: () => '', // TODO: Custom filter dropdown for distinct groups. Low pri
        width: props.width,
        minWidth: props.minWidth,
        flexWidth: props.flexWidth,
        pinned: props.pinned,
        cellTitle: props.cellTitle,
        filterField: props.filterField,
        cellStyle: props.cellStyle,
        disableMenu: props.disableMenu,
        classFn: props.classFn,
        getCopyValue: props.getCopyValue,
        disableResize: props.disableResize,
        sortField: props.sortField,
        sortable: props.sortable,
        parentGroupId: parentGroupId,
        hide: props.onlyShowOnGroupBy ? !dataGridControl.value.nodeData.shouldShowColumn() : undefined,
        hideFromChooser: props.onlyShowOnGroupBy ? !dataGridControl.value.nodeData.shouldShowColumn() : undefined,
        format: props.format,

        headerTextSlot: TreeHeaderSlot,
        cellRenderer: ExpandableCellRenderer,
        cellRendererParams: props.cellRendererParams ?? props.cellrendererparams ?? {
            handleClick: (row: NodeItem, _col: DataColumn) => {
                if (row.expanded) {
                    row.collapse();
                } else {
                    row.expand();
                }
            },
            getLeftMargin: (row: NodeItem) => (row.level ?? 0) * (typeof props.indent === 'string' ? parseFloat(props.indent) : props.indent),
            isExpandable: (row: NodeItem) => row.hasNodes,
            isCollapsed: (row: NodeItem) => !row.expanded,
            isLoading: (row: NodeItem) => row.isLoading,
            hasPrefix: props.showPrefix,
            getPrefix: (row: NodeItem) => {
                if (row.getConfiguration) {
                    const configuration = row.getConfiguration();
                    return configuration.type === 'groupby'
                        ? `${configuration.ui.title}: `
                        : null;
                } else {
                    return null;
                }
            },
            getDisplay: props.getDisplay ?? ((row, col) => {
                let value: any = undefined;
                if (row.displayValue !== undefined) {
                    value = row.displayValue;
                } else if (!row.displayField || props.field == row.displayField) {
                    value = col.format
                        ? utils.format(row[(props.field ?? 'ID')], col)
                        : row[(props.field ?? 'ID')];
                }

                if (value == null) {
                    value = $t('Blank');
                }
                return (props.hideCount || !row.count) ? value : `${value} (${props.directDetailsCount ? row.details?.length : row.count})`;
            }),
            boldDisplay: props.boldDisplay,
        }
    };
});

watch(() => props.headerName, (newValue) => {
    changeColumnProperty('headerName', newValue);
});

function getDataColumn() {
    return dataGridControl?.value?.dataColumns?.getColumn(colId) 
}

function changeColumnProperty(propName: string, value: any) {
    const dataColumn = getDataColumn();
    if (dataColumn) {
        if (!dataColumn.hasOwnProperty(propName)) { console.warn('Trying to set invalid prop for column ', propName, colId); return; }
        dataColumn[propName] = value;
    }
}

onMounted(() => {
    if (!dataGridControl?.value?.dataColumns?.initialColumnsMap[columnDefinition.value.colId]) {
        const container: HTMLElement = dataGridControl!.value.container ?? document.getElementById(dataGridControl!.value.id);
        const cols = Array.from(container.querySelector<HTMLElement>('.o365-column-definitions')?.querySelectorAll<HTMLElement>(`[o365-field]`) ?? []);
        const colIndex = cols.findIndex(x => x.getAttribute('o365-field') === columnDefinition.value.colId);
        if (colIndex === -1) { console.warn('Failed to mount column ', columnDefinition.value.colId); return; }
        if (props.groupBy) {
            dataGridControl.value.nodeData.groupBy = true;
        }
        if (props.loadFullStructure) {
            dataGridControl.value.dataObject.nodeData.loadFullStructure = true;
        }
        dataGridControl.value.nodeData.initialize2(props);
        dataGridControl!.value.asyncAddColumn({
            ...columnDefinition.value,
            class: attrs.class,
            cellRenderSlot: slots.default,
            cellEditorSlot: slots.editor,
            bulkUpdateEditorSlot: slots.batchupdateeditor,
            headerTextSlot: slots.headertext ?? columnDefinition.value.headerTextSlot,
            filterSlot: slots.filter,
            overlaySlot: slots.overlay,
        }, colIndex + 2);
    }
});

onUnmounted(() => {
    if (!dataGridControl?.value?.isBeingUnmounted) {
        dataGridControl!.value.removeColumn(columnDefinition.value.colId);
        delete dataGridControl!.value.dataColumns.initialColumnsMap[columnDefinition.value];
    }
});

defineExpose({ colId });

</script>
